<template>
  <div class="container-fluid conference-apply steps-container-wrapper">
    <h2 class="page-title">
      Apply for Conference
    </h2>
    <cancel-conference-modal
      :conference="conference"
      @conference-canceled="fetchConferences()"
    />
    <submit-conference-modal
      :conference="conference"
    />
    <ifac-conference-application-wizard
      is-owner
      :conference="conference"
      :countries="countries"
      :budget-file-id="budgetFileId"
      :people-list-id="peopleListId"
      :conference-types="conferenceTypes"
      :has-errors="hasErrors(conference)"
      :last-section="lastSection(conference, $route)"
      :uncompleted-sections="uncompletedSections(conference)"
      @change-fees="changeFile('budgetFileId', $event)"
      @change-people-list="changeFile('peopleListId', $event)"
      @update="preparePayload"
      @submit-conference="submitConference"
      @save-exit="updateConferenceStep(true)"
      @cancel-conference="$bvModal.show('cancel-conference')"
      @save-continue="updateConferenceStep"
    />
  </div>
</template>

<script>
import { IfacConferenceApplicationWizard } from '@ifac/ui';
import Conferences from '@/services/Api/Conferences';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import S3Uploader from '@/services/S3Uploader';
import CancelConferenceModal from '@/views/areas/conferences/modals/CancelConferenceModal.vue';
import SubmitConferenceModal from '@/views/areas/conferences/modals/SubmitConferenceModal.vue';

export default {
  components: {
    SubmitConferenceModal,
    CancelConferenceModal,
    IfacConferenceApplicationWizard,
  },
  created() {
    this.fetchConferences();
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      conferenceTypes: 'conferenceTypes/data',
    }),
    ...mapGetters('conferences', {
      conferences: 'conferences',
      activeConference: 'activeConference',
      uncompletedSections: 'uncompletedSections',
      lastSection: 'lastSection',
      hasErrors: 'hasErrors',
    }),
    ...mapState({
      metas: (state) => state.metas,
    }),
  },
  data() {
    return {
      section: null,
      payload: null,
      conference: null,
      budgetFileId: null,
      peopleListId: null,
    };
  },
  watch: {
    activeConference: {
      deep: true,
      handler() {
        if (this.activeConference?.id) {
          this.fetchConference();
        } else this.conference = {};
      },
    },
  },
  methods: {
    ...mapActions({
      fetchConferences: 'conferences/fetchConferences',
    }),
    ...mapMutations({
      setRequestError: 'formHelpers/setError',
      setUploadStatus: 'uiHelpers/setConferenceFileUpload',
    }),
    filePayload($e) {
      return {
        id: this.conference.id,
        fileName: $e.file.name,
      };
    },
    changeFile(field, $e) {
      this.setUploadStatus(true);
      Conferences.uploadFile(this.filePayload($e))
        .then((response) => {
          const { id: uploadId, url } = response.data.data;
          S3Uploader.put(url, $e.file)
            .then(() => {
              this.$data[field] = uploadId;
            }).catch(() => {
              this.$snack.danger({
                text: 'Something went wrong while uploading the file, please try again.',
              });
            });
        }).catch(() => {
          this.$snack.danger({
            text: 'Something went wrong while uploading the file, please try again.',
          });
        }).finally(() => {
          this.setUploadStatus(false);
        });
    },
    preparePayload(data) {
      this.section = data.section;
      this.payload = data.payload;
    },
    updatePayload(id = null) {
      return {
        ...this.payload,
        ...{ id: id || this.activeConference.id },
      };
    },
    submitPayload() {
      return {
        ...this.payload,
        ...this.conference,
      };
    },
    async fetchConference() {
      try {
        const { data } = await Conferences.get(this.activeConference.id);
        this.conference = data.data;
      } catch (error) {
        this.$snack.success({
          text: 'There was an error loading the conference, please refresh the page.',
        });
      }
    },
    async createConference() {
      try {
        // Create a new conference.
        const { data } = await Conferences.create(this.payload);
        // Update the existing active conference with the meta values.
        await this.updateConference(data.data.id);
        // Fetch conferences in order to refresh the active conference value.
        this.fetchConferences();
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async updateConference(id = null) {
      try {
        const { data } = await Conferences.update(this.updatePayload(id));
        this.conference = data.data;
        this.setRequestError(null);
      } catch (error) {
        if (error.response?.status === 422) {
          this.$snack.success({
            text: error.response.data.error.message,
          });
          this.setRequestError(error.response.data.error);
        } else {
          this.$snack.success({
            text: 'There was an error updating the section, please try again.',
          });
        }
      }
    },
    async submitConference() {
      try {
        await this.updateConference();
        await Conferences.submit(this.submitPayload());
        this.$bvModal.show('submit-conference');
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors = error.response.data.error?.details;
        }
      }
    },
    async updateConferenceStep(exit = false) {
      if (!this.activeConference?.id) {
        await this.createConference();
      } else {
        await this.updateConference();
      }

      if (exit) {
        this.$router.push({ name: 'Conferences' });
      }
    },
  },
};
</script>
